@font-face {
    font-family: "fi";
    src: url("/fonts/fi.eot?1");
    src: url("/fonts/fi.eot?#iefix") format("embedded-opentype"),
    url("/fonts/fi.woff?1") format("woff"),
    url("/fonts/fi.ttf?1") format("truetype"),
    url("/fonts/fi.svg?1#fi") format("svg");
    font-weight: normal;
    font-style: normal;
}

@media screen and (-webkit-min-device-pixel-ratio:0) {
    @font-face {
        font-family: "fi";
        src: url("/fonts/fi.svg?1#fi") format("svg");
    }
}

[class^="fi-"]:before, [class*=" fi-"]:before,
[class^="fi-"]:after, [class*=" fi-"]:after {
    font-family: fi;
    font-style: normal;
}

.fi-analytics:before { content: "\f100"; }
.fi-archive:before { content: "\f101"; }
.fi-award:before { content: "\f102"; }
.fi-banking:before { content: "\f103"; }
.fi-banking-1:before { content: "\f104"; }
.fi-bar-chart:before { content: "\f105"; }
.fi-browser:before { content: "\f106"; }
.fi-business:before { content: "\f107"; }
.fi-business-1:before { content: "\f108"; }
.fi-business-2:before { content: "\f109"; }
.fi-business-3:before { content: "\f10a"; }
.fi-business-4:before { content: "\f10b"; }
.fi-business-5:before { content: "\f10c"; }
.fi-business-6:before { content: "\f10d"; }
.fi-business-partnership:before { content: "\f10e"; }
.fi-business-report:before { content: "\f10f"; }
.fi-city:before { content: "\f110"; }
.fi-coffee-shop:before { content: "\f111"; }
.fi-coins:before { content: "\f112"; }
.fi-discount:before { content: "\f113"; }
.fi-documents:before { content: "\f114"; }
.fi-financial:before { content: "\f115"; }
.fi-group:before { content: "\f116"; }
.fi-group-1:before { content: "\f117"; }
.fi-interface:before { content: "\f118"; }
.fi-interface-1:before { content: "\f119"; }
.fi-leadership:before { content: "\f11a"; }
.fi-location:before { content: "\f11b"; }
.fi-man-wearing-business-attire-with-suitcase-in-a-city:before { content: "\f11c"; }
.fi-medal:before { content: "\f11d"; }
.fi-money:before { content: "\f11e"; }
.fi-money-1:before { content: "\f11f"; }
.fi-office:before { content: "\f120"; }
.fi-people:before { content: "\f121"; }
.fi-people-1:before { content: "\f122"; }
.fi-people-2:before { content: "\f123"; }
.fi-people-3:before { content: "\f124"; }
.fi-percentage:before { content: "\f125"; }
.fi-phone-contact:before { content: "\f126"; }
.fi-profit:before { content: "\f127"; }
.fi-profits:before { content: "\f128"; }
.fi-property:before { content: "\f129"; }
.fi-rankings:before { content: "\f12a"; }
.fi-search:before { content: "\f12b"; }
.fi-searching-for-home:before { content: "\f12c"; }
.fi-settings:before { content: "\f12d"; }
.fi-signature:before { content: "\f12e"; }
.fi-stats:before { content: "\f12f"; }
.fi-store:before { content: "\f130"; }
.fi-store-1:before { content: "\f131"; }