	/*
  	Flaticon icon font: Flaticon
  	Creation date: 16/03/2016 09:46
  	*/

@font-face {
  font-family: "Flaticon";
  src: url("/fonts/flat-icon-2/Flaticon.eot");
  src: url("/fonts/flat-icon-2/Flaticon.eot?#iefix") format("embedded-opentype"),
       url("/fonts/flat-icon-2/Flaticon.woff") format("woff"),
       url("/fonts/flat-icon-2/Flaticon.ttf") format("truetype"),
       url("/fonts/flat-icon-2/Flaticon.svg#Flaticon") format("svg");
  font-weight: normal;
  font-style: normal;
}

@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: "Flaticon";
    src: url("/fonts/flat-icon-2/Flaticon.svg#Flaticon") format("svg");
  }
}

[class^="flaticon-"]:before, [class*=" flaticon-"]:before,
[class^="flaticon-"]:after, [class*=" flaticon-"]:after {   
  font-family: Flaticon;
  font-style: normal;
}

.flaticon-clock:before { content: "\f100"; }
.flaticon-placeholder:before { content: "\f101"; }
.flaticon-technology:before { content: "\f102"; }