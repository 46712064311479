/* Filter the 'background' value classes*/

.top_header, .bottom_header .address_container .icon_holder, .top_header div.dropdown ul,
.title_container span.decor_default, footer .footer_widget_title span.decor_default, .finance_text h3 span,
.title_container span.decor-equal, .banner-title span.decor-equal, .parallax .overlay,
.button-main, .our_partners, .banner ul li a.consultation, footer .subscribe_us button, footer .subscribe_us ul li a:hover,
footer .subscribe_us ul li a:focus, .consultation .dropdown ul li:hover, .breadcrumb_sec ul li.dot,
.carousel.vertical .carousel-control.down, .carousel.vertical .carousel-indicators li.active,
.growth_statistics ul li:nth-child(1):before, #customer-say-slider .carousel-indicators li:before,
.cart_table .payment_system a, .service_single_page_content .tab-content .carousel .carousel-indicators li:before,
.key_benefits_panel .panel-heading.active-panel, .brochures a:hover span, .brochures a:focus span,
.submit_form form button.submit, .submit_form form .dropdown ul li:hover, .lets_talk_to_us form .dropdown ul li:hover,
.login_page .submit_button button, .news_single_post .news_post .share_item ul li a, .hvr-sweep-to-right:before,
.news_single_post .news_post .leave_reply button, .twitter_feeds .single_twitter_feed .icon_holder,
.single_project_page .image_gallery ul li a i, .project_gallery .gallery_menu ul li.active, .shop_contianer .result_shorting .dropdown ul li:hover,
.shop_contianer .pagination ul li a:hover, .shop_contianer .pagination ul li.active a, .shop_contianer .single_product_item .product_history a.add_to_cart:hover,
.shop_aside .single-sidebar.price-ranger .ui-widget-header, .cart .cart_table .icon_holder, .cart a.cart_btn1, .cart a.cart_btn2,
.cart .shipping_address .submit_form2 .dropdown-menu li:hover, .banner .title_container span.decor-equal:before, .banner .title_container span.decor-equal:after,
.banner .title_container span.decor_default:before, .banner .title_container span.decor_default:after, .switcher .switch_btn button,
.onoffswitch-checkbox:checked + .onoffswitch-label, .onoffswitch-checkbox:checked + .onoffswitch-label,
.switcher .switch_menu > p, .banner ul li a.consultation_button, .banner .tp-rightarrow:hover, .banner .tp-leftarrow:hover, .ui-widget-content .ui-state-focus {
    background: #9E0E0A;
}

footer .footer_widget_title span.decor_default:before, footer .footer_widget_title span.decor_default:after {
    background: #000000;
}

/* Filter the 'color' value classes*/

.bottom_header .address_container .text_holder span, .tparrows.tp-leftarrow:before, .banner .tparrows.tp-rightarrow:before, .finance_text h3,
.finance_text h3:before, .finance_text a, #best-services .owl_slider .customNavigation a i:hover, .icon_border span,
.people_choose_us span.icon:before, .parallax .button a, .team .member_info > span, .team .member_info a,
.latest_news .single_news a.read_more:hover, .news_aside h3, .latest_news .single_news .img_holder .icon_holder a,
.banner .slide_show h6, footer .links ul li a:hover, footer .contact_us ul li a:hover, .carousel.vertical .carousel-indicators li span,
.mission_value .ticker-headline ul li i, #customer-say-slider .flaticon-quotes3:before, #customer-say-slider .client_name span,
.experts_planning h3 span:before, .experts_planning ul li:before, .experts_planning ul li a:hover,
.service_single_page_content .tab_nav_holder ul li.active a, .service_single_page_content .tab_nav_holder ul li:focus a,
.service_single_page_content .tab_nav_holder ul li a:hover, .service_single_page_content .tab-content .carousel .item a,
.brochures a:hover, .brochures a:focus, .contact_text .contact_num p span, .contact_text .meet_office .contact_information h5,
.contact_text .meet_office .mail:before, .contact_text .meet_office .address:before, .login_page .login_option .social_icon > a,
article.news_post .post_heading ul li a:hover, article.news_post .post_heading ul li:hover i, .news_single_post .news_post .author_text p a:hover,
.news_single_post .news_post .commment_area .single_comment a.reply, .blog_feeds .single_feeds .text_holder ul li i,
.news_categories ul li a i, .news_categories ul li a:hover, .twitter_feeds .single_twitter_feed .feed p a, .twitter_feeds .single_twitter_feed .feed span,
.popular_tag ul li a:hover, article.news_post .share_read a.read_more:hover, article.news_post .pagination_menu ul li a.active,
article.news_post .pagination_menu ul li a:hover, .description_list li > i, .description_list li ul li a:hover, .project_gallery .mix:hover .text_data a span,
.shop_contianer .single_product_item .img_holder .overlay .icon_container a i, .single_shop .product_container .overlay .icon_container a i, .shop ul.product_rating li a,
.shop_contianer .single_product_item .product_history span, .shop_aside .best_sellers .single_sell_item .book_price span, .cart .cart_table .table-1 tbody tr td span.color_fix,
.single_product_details .product_container .product_history .book_review span, .single_product_details .product_container .product_history .book_name .author_name p span,
.single_product_details .product_container .product_history > span, .tparrows.tp-leftarrow:before,
.banner .tparrows.tp-rightarrow:before {
    color: #9E0E0A;
}

/*make sure the slider navigation arrow color still white*/
.banner .tp-rightarrow:hover:before,
.banner .tp-leftarrow:hover:before {
    color: #fff;
}

/* Change the main menu background color */
.main_menu {
    background: #9E0E0A;
}

/* Filter the 3px rgba border value classes */

footer .subscribe_us ul li a:hover,
footer .subscribe_us ul li a:focus {
    border: 3px solid rgba(53, 74, 107, 0.2);
    -moz-background-clip: padding; /* Firefox 3.6 */
    -webkit-background-clip: padding; /* Safari 4? Chrome 6? */
    background-clip: padding-box;
}

/* Slider navigation color change */

.banner .tp-rightarrow:hover,
.banner .tp-leftarrow:hover {
    background: #1F7D4A;
    border: 5px solid rgba(53, 74, 107, 0.2);
    -moz-background-clip: padding; /* Firefox 3.6 */
    -webkit-background-clip: padding; /* Safari 4? Chrome 6? */
    background-clip: padding-box; /* Firefox 4, Safari 5, Opera 10, IE 9 */
}

/* Filter the 2px rgba border value classes */

.title_container span.decor_default,
.title_container span.decor-equal,
.banner-title span.decor-equal,
.breadcrumb_sec ul li.dot,
.carousel.vertical .carousel-control.down,
.carousel.vertical .carousel-indicators li.active,
.growth_statistics ul li:nth-child(1):before,
.news_single_post .news_post .share_item ul li a,
.twitter_feeds .single_twitter_feed .icon_holder,
.shop_contianer .pagination ul li a:hover,
.shop_contianer .pagination ul li.active a {
    border: 2px solid rgba(53, 74, 107, 0.3);
    -moz-background-clip: padding;
    -webkit-background-clip: padding;
    background-clip: padding-box;
}

footer .footer_widget_title span.decor_default {
    border: 2px solid rgba(0, 0, 0, 0.3);
    -moz-background-clip: padding;
    -webkit-background-clip: padding;
    background-clip: padding-box;
}

/* Filter the 6px rgba border value classes */

.bottom_header .address_container .icon_holder {
    border: 6px solid rgba(53, 74, 107, 0.10);
    -moz-background-clip: padding;
    -webkit-background-clip: padding;
    background-clip: padding-box;
}

/* Filter the 2px solid border value classes */

.service_icon .icon_border,
.faq_sec .panel-default > a[aria-expanded="true"] .panel-heading:before,
.faq_sec .panel-default > a:focus .panel-heading:before,
#customer-say-slider .carousel-indicators li,
#customer-say-slider .carousel-indicators li.active,
.service_single_page_content .tab-content .carousel .carousel-indicators li,
.service_single_page_content .tab-content .carousel .carousel-indicators li.active {
    border: 2px solid #9E0E0A;
}

/* Filter the background rgba color value classes*/

.testimonial .img_holder .overlay,
.gallery_sec .gallery_img .overlay,
.hvr-sweep-to-bottom:before,
.news_aside .aside_item .overlay,
article.news_post .single_news_post .img_container:before,
.hvr-shutter-out-vertical:before,
.single_project_page .image_gallery ul li a .opacity_holder .bg_color,
.shop_contianer .single_product_item .img_holder .overlay,
.single_shop .product_container .overlay {
    background: rgba(158, 14, 10, 0.7);
}

/* change the search icon background color */

.main_menu .search_icon, .main_menu #search {
    background-color: #1F7D4A;
}

/* change the theme logo for your own color schemes */

.bottom_header .logo_holder {
}

.text_container span.logo,
footer .footer_logo a.logo {
    background-image: url(../../images/logo.png);
    width: 217px;
    overflow-y: hidden ! important;
    overflow-x: hidden ! important;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: right;
}

.text_container span.logo {
    margin-left: 30px;
}

.banner .logo span.logo {
    display: block;
    background: url(../../images/home/logo-icon2.png) no-repeat center;
    height: 79px;
    width: 70px;
}

/* Contain the color schemes when the menu is stick*/
.fixed {
    position: fixed;
    background: #272727;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 9999;
    animation-name: fixed-bouce;
    animation-duration: 1s;
    -webkit-box-shadow: 0px 13px 30px -12px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 0px 13px 30px -12px rgba(0, 0, 0, 0.75);
    box-shadow: 0px 13px 30px -12px rgba(0, 0, 0, 0.75);
}

.fixed .search_icon {
    background-color: #1F7D4A;
}

.fixed #search {
    background: #1F7D4A;
}

.cover {
    background-size: cover;
    background-attachment: fixed;
    background-position: center center;
    background-repeat: no-repeat;
    margin-left: -15px;
    margin-right: -15px;
}

.touch .cover {
    background-attachment: scroll !important;
}

.m20 {
    margin: 20px;
}

.m30 {
    margin: 30px;
}

.ml15 {
    margin-left: 15px;
}

.mt30 {
    margin-top: 30px;
}

.mt20 {
    margin-top: 20px;
}

.mb30 {
    margin-bottom: 30px;
}

.mb10 {
    margin-bottom: 10px;
}

.p20 {
    padding: 20px;
}

.p30 {
    padding: 30px;
}

.pt100 {
    padding-top: 100px;
}

.pb100 {
    padding-bottom: 100px;
}

.pl30 {
    padding-left: 30px;
}

.pt10 {
    padding-top: 10px;
}

.pl10 {
    padding-left: 10px;
}

.pr20 {
    padding-right: 20px;
}

.pr30 {
    padding-right: 30px;
}

.pb30 {
    padding-bottom: 30px;
}

.testimonial .item .icon {
    font-size: 42px;
    color: #ffffff;
}

.icon_holder .icon {
    font-size: 42px;
    color: #1F7D4A;
}

.section-bg {
    background-color: #f4f4f4;
}

.red-bg {
    background-color: #9E0E0A;
    color: #fff;
    border: #9E0E0A 1px solid !important;
}

.red {
    color: #9E0E0A;
}

.f10 {
    font-size: 10px;
}

.f22 {
    font-size: 22px;
}

.mlr-15 {
    margin-left: -30px !important;
    margin-right: -30px !important;
}

.title_container {
    margin-top: 30px;
}

.text ul li {
    list-style: circle;
    list-style-type: disc;
    margin-left: 25px;
    /*padding-left: 5px;*/
}

.panel-title {
    margin-right: 20px;
}

.service_text h3.red {
    position: absolute;
    bottom: 20px;
    text-align: center;
    width: 100%;
}

.service_text h3.red span {
    margin-left: -30px;
}

.price-ranger table {
    width: 100%;
}

.price-ranger td {
    width: 20%;
}

.error {
    color: red;
}

a.mob-tel {
    font-size: 32px;
    line-height: 32px;
    color: #fff;
    display: block;
    position: relative;
    top: 19px;
    width: 280px;
    margin-left: 40px;
}
@media (min-width: 1024px) {
    .container-fluid > .row {
        padding: 25px 100px 25px 100px;
    }
}
.text {
    padding-left: 20px;
}